@font-face {
    font-family: "Clash Display";
    src: url("./assets/ClashDisplay-Bold.otf") format("opentype");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

html {
    font-size: 62.5%;
    font-weight: normal;
    font-family: "Barlow", sans-serif;
}

body {
    margin: 0;
    color: #fff;
    font-size: 1.6rem;
    font-weight: normal;
    background-color: #161616;
    font-family: "Barlow", sans-serif;
}

p,
span,
h1,
h2,
h3,
h4,
h5,
h6,
label {
    margin: 0;
    font-weight: normal;
    font-family: "Barlow", sans-serif;
}

.app-container {
    display: flex;
    max-width: 128rem;
    align-items: flex-start;
    margin: 4rem auto 5.2rem auto;
}

.secondary-font {
    font-family: "Clash Display", sans-serif;
}

.outline-btn {
    text-align: center;
    display: inline-block;
    cursor: pointer;
    font-size: 2rem;
    color: #161616;
    font-weight: 700;
    border-radius: 10rem;
    text-decoration: none;
    padding: 1.6rem 4.8rem;
    text-transform: uppercase;
    border: 2px solid #161616;
    background-color: transparent;
    font-family: "Clash Display", sans-serif;
    transition: box-shadow 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
    -webkit-transition: box-shadow 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.outline-btn:hover {
    color: #fff;
    box-shadow: none;
    background-color: #161616;
}

.outline-btn.primary {
    color: #fff;
    border-color: #fffc00;
}

.outline-btn.primary:hover {
    color: #161616;
    background-color: #ffd;
    box-shadow: 0 0 30px 0 #fffc00;
}

.outline-btn.mini-btn {
    font-size: 1.4rem;
    padding: 1rem 2rem 0.8rem 2rem;
}

.mobile {
    display: none;
}

@media screen and (max-width: 992px) {
    .mobile {
        display: block;
    }

    .desktop {
        display: none;
    }
}